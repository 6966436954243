<script setup lang="ts">
import { ChatBubbleLeftRightIcon, CloudIcon, UserCircleIcon, MapPinIcon } from '@heroicons/vue/24/outline';

import HomeCardComponent from '@/components/HomeCardComponent.vue';
import { ref } from 'vue';

import { userManager } from '@/oidc/plugin'

let user: any = await userManager.getUser()

const ref_def_profan = ref();

function highlight(ref: any) {
  ref.classList.add('bg-slate-100');
  setTimeout(() => { ref.classList.remove('bg-slate-100') }, 1500)
}

function connect() {
  userManager.signinRedirect()
}
</script>

<template>

  <div class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0 w-full">

    <div
      class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div
        class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="lg:max-w-lg">
            <img src="/ProFAN.svg" alt="" class="lg:hidden w-52 mb-10" width="208" height="59">

            <h1 class="mt-2 text-2xl  sm:text-3xl  font-bold tracking-tight text-[#4b559c]">Bienvenue sur la plateforme
              <a href="#def-profan" class="underline" @click="highlight(ref_def_profan)">ProFAN Transfert</a>&nbsp;!
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-700">
              Cette plateforme est destinée à la diffusion de ressources et au recueil d’informations utiles au suivi et
              à l’évaluation continue du dispositif ProFAN-Transfert et de son efficacité. Elle est un espace de travail
              partagé avec les chercheurs membres du consortium et entre tous les participants, professeurs, formateurs,
              membres des corps d’inspection, tout au long du déroulement de l’opération.
            </p>
            <br>
            <p class="text-lg leading-8 text-gray-700">
              Elle est mise à la disposition des utilisateurs à des fins de culture, de formation, d’enseignement, de
              recherche et de diffusion d’informations scientifiques et pédagogiques.
            </p>
            <br>
            <p class="text-lg leading-8 text-gray-700">
              Le cœur de cette plateforme est constitué de deux applications collaboratives : un espace de partage de
              contenus (le Cloud) et un espace d’échange et de communication (le Forum).
            </p>

            <div class="ring-1 ring-offset-8 ring-gray-300 rounded-sm">
              <div class="flex flex-row justify-start items-center gap-4 mt-8">
                <div class="w-16 h-16 p-2 rounded-md bg-amber-100 text-yellow-500">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M6.09 14.999a6.9 6.9 0 0 1-.59-2.794C5.5 8.5 8.41 5.499 12 5.499s6.5 3.002 6.5 6.706a6.9 6.9 0 0 1-.59 2.794m-5.91-13v1m10 9h-1m-18 0H2m17.07-7.071l-.707.707m-12.726.001l-.707-.707m9.587 14.377c1.01-.327 1.416-1.252 1.53-2.182c.034-.278-.195-.509-.475-.509H8.477a.483.483 0 0 0-.488.534c.112.928.394 1.606 1.464 2.156m5.064 0H9.453m5.064 0c-.121 1.945-.683 2.716-2.51 2.694c-1.954.036-2.404-.916-2.554-2.693"
                      color="currentColor" />
                  </svg>

                </div>
                <h2 class="text-2xl font-bold tracking-tight text-gray-900">Que trouve-t-on sur le Cloud ? </h2>
              </div>
              <p class="mt-2 text-lg leading-8 text-gray-700">
                Des ressources scientifiques (conférences, articles, podcasts, etc.), des documents utiles à la
                co-construction de programmes de formation développés au sein des académies ainsi que leur contenu.
              </p>
              <br>
              <p class="text-lg leading-8 text-gray-700">
                Il s’agit d’un explorateur de fichiers organisés par et pour les contributeurs. L’utilisation d’un moteur de recherche et/ou une navigation guidée par le nom des dossiers est recommandée.

              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden lg:inline-block -ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
        <div class="container flex flex-col justify-center gap-2 w-3/4  lg:w-[800px] flex-wrap">
          <img src="/ProFAN.svg" alt="" class="hidden lg:inline-block w-52 mb-10" width="208" height="59">
          <div v-if="user" class="container flex flex-col justify-center gap-2 w-3/4  lg:w-[800px] flex-wrap">
            <p class="text-base font-semibold leading-7 text-blue-600">Applications principales</p>
            <HomeCardComponent :title="'Cloud'"
              :p="'Consulter des ressources, enregistrer, éditer et partager des documents.'" :href="'/cloud'"
              :component="CloudIcon" class=" min-h-[150px] max-w-[90vw]" />
            <HomeCardComponent 
                :title="'Forum'" 
                :p="'Poser des questions, discuter, échanger et partager des idées et des propositions.'" 
                :href="'/forum'"
                :component="ChatBubbleLeftRightIcon"
                class=" min-h-[150px] max-w-[90vw]"
                /> 

            
            <p class="mt-4 text-base font-semibold leading-7 text-blue-600 flex flex-row items-center gap-2">
              <MapPinIcon class="w-6 h-6" aria-hidden="true" />
              Astuces de navigation
            </p>
            <p>
              Pour consulter :
              <ul class="list-disc pl-5 mt-2 space-y-2 text-gray-700 w-[25rem] max-w-[90vw]">
              <li>le <b>guide utilisateur</b>, rdv dans « Guide et charte »</li>
              <li>les <b>vidéos du séminaire de Bordeaux</b>, rdv dans « Les conférences du séminaire de Bordeaux 2023 »</li>
              <li>les <b>vidéos du séminaire d'Arcachon</b>, rdv dans « Le séminaire d'Arcachon 2024 »</li>
              <li>des <b>ressources complémentaires</b> (articles, chapitres, podcast etc..), rdv dans « Pour aller plus loin – Ressources en langue française 2023 »</li>
              <li>les <b>espaces de travail des académies de la première vague</b>, rdv dans « Vos contributions – Cohorte 2023 »</li>
              <li>les <b>espaces de travail des académies de la deuxième vague</b>, rdv dans « Vos contributions – Cohorte 2024 »</li>
              </ul>
            </p>



          </div>
          <div v-else class="container flex flex-col justify-center gap-2 w-3/4  lg:w-[800px] flex-wrap">
            <button @click="connect" aria-label="Bouton de connection"
              class="flex flex-col w-max border-2 border-gray-200 rounded-md p-4 text-gray-500 group hover:border-blue-200 cursor-pointer">
              <div class="flex flex-row gap-2 text-gray-700 group-hover:text-blue-500">
                <UserCircleIcon class="w-7 h-7" aria-hidden="true" />
                <h2 class="text-xl font-bold">Se connecter</h2>
              </div>
            </button>
          </div>

        </div>
      </div>
      <div
        class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
            
            <p class="text-lg">Outre les deux applications principales : le Forum et le Cloud, des formulaires peuvent vous être
              proposés ponctuellement et pour une durée limitée, afin de documenter l’avancement du projet dans ses
              différentes étapes.</p>
            <br>
            <p class="text-lg"><b>ProFAN Transfert</b> est un dispositif national financé par le <b>SGPI</b> dans le cadre du <b>PIA
                France 2030</b>. Conçu par la Mission Monteil qui en assure le pilotage, en relation avec la DGESCO, il
              est opéré par l’Agence nationale pour la recherche et s’inscrit dans la mesure 12 de la réforme du lycée
              professionnel, « Permettre une nouvelle approche pédagogique autour du projet de l’élève ».
            </p>
            <br>
            <p id="def-profan" ref="ref_def_profan" class="mt-8 transition-colors duration-700 ease-in-out">* ProFAN Transfert est conçue par le consortium de recherche ProFAN Transfert, développée, administrée et maintenue par les chercheurs du
               <a href="https://limos.fr/"
                class="underline text-blue-700">LIMOS</a> (Laboratoire d’Informatique de Modélisation et d’Optimisation des Systèmes) laboratoire CNRS et UCA, membre du consortium ProFAN.</p>
            <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Un problème ?</h2>
            <p class="mt-6">L'équipe du LIMOS se tient à votre écoute en cas de problème. Vous pouvez nous contacter en
              cliquant sur le bouton "Feedback" dans la barre latérale gauche (en y déplaçant votre curseur) ou par mail
              <u>profan-transfert@limos.fr</u> .</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>